.xc-layout 
{
    overflow: hidden;
    transition: 1.0s;
}

.btn.btn-primary
{
    background: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    color: white !important;
    transition: 1.0s;
    --bs-btn-color: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-bg: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-border-color: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-hover-color: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-hover-bg: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-hover-border-color: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-active-bg: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-active-border-color: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-disabled-bg: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    --bs-btn-disabled-border-color: linear-gradient(to bottom, #000000 40%, #5946ff 100%) !important;
    margin: 3px !important;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active
{
    box-shadow: 0 10px 10px 0 blue, 0 6px 20px 0 blue !important;
  
}